export const calculateEncounterXP = (partySize, difficulty) => {
    const xpThresholds = {
      Easy: 25,
      Medium: 50,
      Hard: 75,
      Deadly: 100,
    };
    return partySize * xpThresholds[difficulty];
  };
  
  export const generateEncounterMonsters = (partySize, difficulty) => {
    const encounterXP = calculateEncounterXP(partySize, difficulty);
    const monsters = [
      { name: "Goblin", cr: 0.25, xp: 50 },
      { name: "Orc", cr: 0.5, xp: 100 },
      { name: "Troll", cr: 1, xp: 200 },
    ];
    const selectedMonsters = [];
    let totalXP = 0;
  
    while (totalXP < encounterXP) {
      const monster = monsters[Math.floor(Math.random() * monsters.length)];
      if (totalXP + monster.xp <= encounterXP) {
        selectedMonsters.push(monster);
        totalXP += monster.xp;
      } else {
        break;
      }
    }
    return selectedMonsters;
  };
  
  export const generateLoot = (lootValue) => {
    const lootTable = {
      Low: [{ name: "Gold Coins", range: [10, 50] }],
      Medium: [
        { name: "Gold Coins", range: [50, 200] },
        { name: "Potion of Healing", rarity: "Common" },
      ],
      High: [
        { name: "Gold Coins", range: [200, 500] },
        { name: "Potion of Greater Healing", rarity: "Uncommon" },
      ],
    };
  
    const lootItems = lootTable[lootValue].map((item) => {
      const amount = item.range
        ? Math.floor(
            Math.random() * (item.range[1] - item.range[0] + 1) + item.range[0]
          )
        : null;
      return { ...item, amount };
    });
  
    return lootItems;
  };
  