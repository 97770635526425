import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";

function Dashboard() {
  const [characters, setCharacters] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCharacters = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate("/");
        return;
      }

      const q = query(
        collection(db, "characters"),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      const chars = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCharacters(chars);
    };

    fetchCharacters();
  }, [navigate]);

  const handleSelectCharacter = (character) => {
    navigate("/play-game", { state: { character } });
  };

  const handleDeleteCharacter = async (characterId) => {
    try {
      await deleteDoc(doc(db, "characters", characterId));
      setCharacters(characters.filter((char) => char.id !== characterId));
    } catch (error) {
      console.error("Error deleting character:", error);
    }
  };

  const showModal = (char) => {
    setModalContent(char);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div className="dashboard-container">
      <h1 className="typing-text">Your Characters</h1>
      <p className="intro-text">
        Manage your heroes, view their stats, or jump into an adventure!
      </p>
      <ul className="character-list">
        {[...Array(4)].map((_, index) => {
          const char = characters[index];
          return char ? (
            <li key={char.id} className="character-card">
              <h2>{char.name}</h2>
              {char.portrait && (
                <img
                  src={char.portrait}
                  alt={`${char.name}'s portrait`}
                  className="portrait-thumbnail"
                  onError={(e) => {
                    console.error("Error loading portrait for", char.name, e);
                    e.target.src = "/img/default-portrait.png"; // Fallback image
                  }}
                />
              )}
              <div className="button-row">
                <img
                  src="/img/info128.png"
                  alt="Show Details"
                  className="icon-button"
                  onClick={() => showModal(char)}
                />
                <img
                  src="/img/play128.png"
                  alt="Play"
                  className="icon-button"
                  onClick={() => handleSelectCharacter(char)}
                />
                <img
                  src="/img/delete128.png"
                  alt="Delete"
                  className="icon-button"
                  onClick={() => handleDeleteCharacter(char.id)}
                />
              </div>
            </li>
          ) : (
            <li
              key={index}
              className="character-card placeholder-card"
              onClick={() => navigate("/test-page")}
            >
              <h2>Create Character</h2>
            </li>
          );
        })}
      </ul>
  
      {modalContent && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content-db"
            onClick={(e) => e.stopPropagation()}
          >
            <h1>{modalContent.name}</h1>
            <img
              src={modalContent.portrait}
              alt={`${modalContent.name}'s portrait`}
              className="modal-thumbnail"
              onError={(e) => {
                console.error(
                  "Error loading portrait for",
                  modalContent.name,
                  e
                );
                e.target.src = "/img/default-portrait.png"; // Fallback image
              }}
            />
            <div className="modal-section">
              <h3>About</h3>
              <div className="modal-columns">
                {(() => {
                  const aboutDetails = [
                    `Class: ${modalContent.class}`,
                    `Race: ${modalContent.race}`,
                    `Level: ${modalContent.level}`,
                    `Alignment: ${modalContent.alignment}`,
                    `Background: ${modalContent.background}`,
                    `Gender: ${modalContent.gender}`,
                    `Hitpoints: ${modalContent.hitPoints || 0}`,
                    `Hit Die: d${modalContent.hitDie}`,
                    `Experience: ${modalContent.experience}`,
                    `Gold: ${modalContent.gold}`,
                  ];
  
                  return (
                    <>
                      <div className="column">
                        {aboutDetails
                          .slice(0, Math.ceil(aboutDetails.length / 2))
                          .map((detail, index) => (
                            <p key={index}>{detail}</p>
                          ))}
                      </div>
                      <div className="column">
                        {aboutDetails
                          .slice(Math.ceil(aboutDetails.length / 2))
                          .map((detail, index) => (
                            <p key={index}>{detail}</p>
                          ))}
                      </div>
                    </>
                  );
                })()}
              </div>
            </div>
  
            <div className="modal-section">
              <h3>Character Stats</h3>
              <div className="modal-columns">
                {(() => {
                  const statsDetails = [
                    `Str: ${modalContent.stats.str}`,
                    `Dex: ${modalContent.stats.dex}`,
                    `Con: ${modalContent.stats.con}`,
                    `Int: ${modalContent.stats.int}`,
                    `Wis: ${modalContent.stats.wis}`,
                    `Cha: ${modalContent.stats.cha}`,
                  ];
  
                  return (
                    <>
                      <div className="column">
                        {statsDetails
                          .slice(0, Math.ceil(statsDetails.length / 2))
                          .map((stat, index) => (
                            <p key={index}>{stat}</p>
                          ))}
                      </div>
                      <div className="column">
                        {statsDetails
                          .slice(Math.ceil(statsDetails.length / 2))
                          .map((stat, index) => (
                            <p key={index}>{stat}</p>
                          ))}
                      </div>
                    </>
                  );
                })()}
              </div>
            </div>
  
            <div className="modal-section">
              <h3>Proficiencies</h3>
              <div className="modal-columns">
                {modalContent.proficientSkills ? (
                  (() => {
                    const skillsArray =
                      Array.isArray(modalContent.proficientSkills)
                        ? modalContent.proficientSkills
                        : modalContent.proficientSkills.split(",").map((skill) => skill.trim());
                    return (
                      <>
                        <div className="column">
                          {skillsArray
                            .slice(0, Math.ceil(skillsArray.length / 2))
                            .map((skill, index) => (
                              <p key={index}>{skill}</p>
                            ))}
                        </div>
                        <div className="column">
                          {skillsArray
                            .slice(Math.ceil(skillsArray.length / 2))
                            .map((skill, index) => (
                              <p key={index}>{skill}</p>
                            ))}
                        </div>
                      </>
                    );
                  })()
                ) : (
                  <p>No proficiencies available</p>
                )}
              </div>
            </div>
             {/* Inventory Section */}
          <div className="modal-section">
            <h3>Inventory</h3>
            <div className="modal-columns">
              {modalContent.inventory && modalContent.inventory.length > 0 ? (
                (() => {
                  return (
                    <>
                      <div className="column">
                        {modalContent.inventory
                          .slice(0, Math.ceil(modalContent.inventory.length / 2))
                          .map((item, index) => (
                            <p key={index}>{item.name}</p>
                          ))}
                      </div>
                      <div className="column">
                        {modalContent.inventory
                          .slice(Math.ceil(modalContent.inventory.length / 2))
                          .map((item, index) => (
                            <p key={index}>{item.name}</p>
                          ))}
                      </div>
                    </>
                  );
                })()
              ) : (
                <p>No items in inventory</p>
              )}
            </div>
          </div>

          {/* Dungeon Master Section */}
          <div className="modal-section">
            <h3>Dungeon Guide</h3>
            <div className="modal-columns">
              <div className="column">
                <p>
                  {modalContent.dungeonMaster?.name || "No Dungeon Guide selected"}
                </p>
                {modalContent.dungeonMaster?.image && (
                  <img
                    src={modalContent.dungeonMaster.image}
                    alt={`${modalContent.dungeonMaster.name}'s portrait`}
                    className="modal-thumbnail"
                    onError={(e) => {
                      console.error(
                        "Error loading portrait for Dungeon Guide",
                        e
                      );
                      e.target.src = "/img/default-portrait.png"; // Fallback image
                    }}
                  />
                )}
              </div>
              <div className="column">
                <p>
                  {modalContent.dungeonMaster?.description || "No description available"}
                </p>
              </div>
            </div>
          </div>
  
            <button className="roll-button go-back-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
  
      <button
        className="roll-button go-back-button"
        onClick={() => navigate("/options")}
      >
        Go Back
      </button>
    </div>
  );
  
  
}

export default Dashboard;
