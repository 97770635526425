import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import "./Login.css";
import googleIcon from "../img/googleicon.png";
//import AdSenseAd from "../components/AdSenseAd";

function Login() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/options");
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      alert("Failed to sign in with Google");
    }
  };

  return (
    <div className="login-container">
      <h1 className="typing-text">Welcome to <strong className="highlight-green">TheQuest.ai!</strong></h1>
      <div>
        <h3>Step into a World of Endless Possibilities!</h3>
        <p>
          Forge your hero with our powerful character builder and dive into an epic campaign guided by an{" "}
          <strong className="highlight-green">AI Dungeon Guide</strong> tailored to your playstyle. Choose a unique
          Dungeon Guide persona to bring your story to life, or explore our incredible tools — perfect for your
          tabletop needs.
        </p>
        <h1>Your adventure awaits!</h1>
      </div>

      <button className="google-button" onClick={handleGoogleSignIn}>
        <img src={googleIcon} alt="Google icon" className="google-icon" />
        Sign in with Google
      </button>

      <footer className="footer-text">
        <p>Embark on an unforgettable adventure today!</p>
      </footer>
    </div>
  );
};


export default Login;
