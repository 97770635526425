// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
//import CharacterCreation from './pages/CharacterCreation';
import PlayGame from './pages/PlayGame';
//import BuyItems from './pages/BuyItems';  // Import BuyItems page
import TestPage from './pages/TestPage';
import DiceRoller from './pages/DiceRoller';
import Options from './pages/Options';
import CharacterSheet from './pages/CharacterSheet';


import LootEncounterGenerator from './pages/Tests/LootEncounterGenerator'; 
import DicePG from './pages/DicePG';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/options" element={<Options />} /> 
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/play-game" element={<PlayGame />} />

        <Route path="/test-page" element={<TestPage />} />
        <Route path="/dice-roller" element={<DiceRoller />}/>
        <Route path="/dicePG" element={<DicePG />}/>
        <Route path="/CharacterSheet" element={<CharacterSheet />}/>
        <Route path="/loot-encounter-generator" element={<LootEncounterGenerator />} />

      </Routes>
    </Router>
  );
}

export default App;
