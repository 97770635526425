import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DicePG.css";

const DiceRoller = () => {
  const [diceConfigs, setDiceConfigs] = useState([{ diceType: "d20", diceCount: 1 }]);
  const [results, setResults] = useState([]);
  const [rolling, setRolling] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();

  const diceSides = {
    d4: 4,
    d6: 6,
    d8: 8,
    d10: 10,
    d12: 12,
    d20: 20,
    d100: 100,
  };

  const rollDice = () => {
    setRolling(true);
    const allResults = diceConfigs.map(({ diceType, diceCount }) => {
      const sides = diceSides[diceType];
      return {
        diceType,
        rolls: Array.from({ length: diceCount }, () => Math.floor(Math.random() * sides) + 1),
      };
    });
    setTimeout(() => {
      setResults(allResults);
      setRolling(false);
      setShowResults(true);
    }, 1500);
  };

  const clearResults = () => {
    setResults([]);
    setShowResults(false);
  };

  const calculateGrandTotal = () => {
    return results.reduce(
      (grandTotal, { rolls }) => grandTotal + rolls.reduce((sum, roll) => sum + roll, 0),
      0
    );
  };

  const addDiceRow = () => {
    if (diceConfigs.length < 7) {
      setDiceConfigs([...diceConfigs, { diceType: "d20", diceCount: 1 }]);
    }
  };

  const removeDiceRow = (index) => {
    setDiceConfigs(diceConfigs.filter((_, i) => i !== index));
  };

  const updateDiceConfig = (index, key, value) => {
    const updatedConfigs = diceConfigs.map((config, i) =>
      i === index ? { ...config, [key]: value } : config
    );
    setDiceConfigs(updatedConfigs);
  };

  return (
    <div className="dice-roller">
      <h1 className="typing-text">Dice Roller</h1>
      <div className="controls">
        {diceConfigs.map((config, index) => (
          <div key={index} className="dice-row">
            <img
              src={`/img/dice/${config.diceType}.png`}
              alt={`${config.diceType} icon`}
              className="dice-icon"
            />
            <label>
              Type:
              <select
                value={config.diceType}
                onChange={(e) => updateDiceConfig(index, "diceType", e.target.value)}
              >
                {Object.keys(diceSides).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Count:
              <select
                value={config.diceCount}
                onChange={(e) => updateDiceConfig(index, "diceCount", Number(e.target.value))}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((count) => (
                  <option key={count} value={count}>
                    {count}
                  </option>
                ))}
              </select>
            </label>
            {index > 0 && (
              <button className="remove-button" onClick={() => removeDiceRow(index)}>
                X
              </button>
            )}
            {index === diceConfigs.length - 1 && diceConfigs.length < 7 && (
              <button className="add-button" onClick={addDiceRow}>
                +
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="buttons-container">
        <button className="roll-button2" onClick={rollDice} disabled={rolling}>
          {rolling ? "Rolling..." : "Roll Dice"}
        </button>
        {showResults && (
          <button className="clear-button" onClick={clearResults}>
            Clear Results
          </button>
        )}
      </div>
      {showResults && (
        <div className="results">
          <h2>Results</h2>
          <div className="results-container">
            {results.map(({ diceType, rolls }, index) => (
              <div key={index} className="result-item">
                <p>
                  <img
                    src={`/img/dice/${diceType}.png`}
                    alt={`${diceType} icon`}
                    className="dice-icon"
                  />
                  <strong>{diceType}:</strong> {rolls.join(", ")}
                </p>
                <p>Total: {rolls.reduce((a, b) => a + b, 0)}</p>
              </div>
            ))}
          </div>
          <div className="grand-total">
            <h3>Grand Total: {calculateGrandTotal()}</h3>
          </div>
        </div>
      )}
      {rolling && <div className="dice-animation">🎲 Rolling...</div>}
    </div>
  );
};

export default DiceRoller;
