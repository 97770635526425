import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import {
  races,
  alignments,
  genders,
  classesMale,
  classesFemale,
  backgrounds,
  portraitOptions,
  dungeonMasters,
} from "../utils/constants";
import "./CharacterSheet.css";

function CharacterSheet() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [character, setCharacter] = useState({
    name: "",
    race: races[0],
    gender: genders[0],
    class: classesMale[0],
    background: Object.keys(backgrounds)[0],
    alignment: alignments[0],
    stats: {},
    gold: 0,
    inventory: [],
    portrait: null,
    dungeonMaster: {
      name: "",
      description: "",
      image: "",
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCharacter((prev) => ({ ...prev, [name]: value }));
  };

  const rollStats = () => {
    const roll = () => {
      const rolls = Array.from({ length: 4 }, () => Math.floor(Math.random() * 6) + 1);
      return rolls.sort().slice(1).reduce((a, b) => a + b, 0);
    };
    setCharacter((prev) => ({
      ...prev,
      stats: {
        str: roll(),
        dex: roll(),
        con: roll(),
        int: roll(),
        wis: roll(),
        cha: roll(),
      },
    }));
  };

  const rollGold = () => {
    const gold = Math.floor(Math.random() * 50) + 10; // Example range
    setCharacter((prev) => ({ ...prev, gold }));
  };

  const confirmCharacter = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("User is not authenticated!");
      return;
    }

    const characterData = {
      ...character,
      userId: user.uid,
    };

    try {
      await addDoc(collection(db, "characters"), characterData);
      alert("Character created successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating character:", error);
      alert("There was an issue creating the character. Please try again.");
    }
  };

  const steps = [
    {
      title: "Welcome, Adventurer!",
      content: (
        <>
          <p>What shall we call you, brave soul?</p>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={character.name}
            onChange={handleInputChange}
          />
        </>
      ),
    },
    {
      title: "Shape Your Journey",
      content: (
        <>
          <p>Choose your race, gender, and class to define your path.</p>
          <label>Race:</label>
          <select name="race" value={character.race} onChange={handleInputChange}>
            {races.map((race) => (
              <option key={race} value={race}>
                {race}
              </option>
            ))}
          </select>
          <label>Gender:</label>
          <select name="gender" value={character.gender} onChange={handleInputChange}>
            {genders.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </select>
          <label>Class:</label>
          <select
            name="class"
            value={character.class}
            onChange={handleInputChange}
          >
            {(character.gender === "Male" ? classesMale : classesFemale).map((cls) => (
              <option key={cls} value={cls}>
                {cls}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "Fate Determines Your Strength",
      content: (
        <>
          <button onClick={rollStats}>Roll Stats</button>
          <div>
            {Object.entries(character.stats).map(([stat, value]) => (
              <p key={stat}>
                {stat.toUpperCase()}: {value}
              </p>
            ))}
          </div>
        </>
      ),
    },
    {
      title: "Your Wealth Awaits",
      content: (
        <>
          <button onClick={rollGold}>Roll Gold</button>
          <p>Gold: {character.gold} gp</p>
        </>
      ),
    },
    {
      title: "Select Your Portrait",
      content: (
        <>
          <div className="portraits">
            {portraitOptions[character.gender]?.[character.race]?.map(
              (portrait, index) => (
                <img
                  key={index}
                  src={portrait}
                  alt="portrait"
                  onClick={() => setCharacter((prev) => ({ ...prev, portrait }))}
                  style={{ cursor: "pointer" }}
                />
              )
            )}
          </div>
        </>
      ),
    },
    {
      title: "Choose a Dungeon Guide",
      content: (
        <>
          <select
            value={character.dungeonMaster.name}
            onChange={(e) =>
              setCharacter((prev) => ({
                ...prev,
                dungeonMaster: {
                  ...prev.dungeonMaster,
                  name: e.target.value,
                },
              }))
            }
          >
            {Object.keys(dungeonMasters).map((dm) => (
              <option key={dm} value={dm}>
                {dm}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "Behold, Your Hero!",
      content: (
        <>
          <p>Name: {character.name}</p>
          <p>Race: {character.race}</p>
          <p>Gender: {character.gender}</p>
          <p>Class: {character.class}</p>
          <p>Gold: {character.gold} gp</p>
          <button onClick={confirmCharacter}>Confirm</button>
        </>
      ),
    },
  ];

  return (
    <div className="character-sheet">
      <div className="card-container">
        {steps.map((stepContent, index) => (
          <div
            key={index}
            className={`card ${step === index + 1 ? "active" : "inactive"}`}
          >
            <h2>{stepContent.title}</h2>
            {stepContent.content}
            <div className="buttons">
              {step > 1 && (
                <button onClick={() => setStep((prev) => prev - 1)}>Back</button>
              )}
              {step < steps.length && (
                <button onClick={() => setStep((prev) => prev + 1)}>Next</button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CharacterSheet;
