// src/pages/Options.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Options.css";

function Options() {
  const navigate = useNavigate();

  return (
    <div className="options-container">
      <h1 className="typing-text">Welcome to TheQuest.ai</h1>
      <p className="intro-text">
        Discover a suite of tools to enhance your adventures. Choose from our
        exciting features below!
      </p>
      <div className="options-grid">
        {/* TheQuest Game */}
        <div className="option-card" onClick={() => navigate("/dashboard")}>
          <h2>TheQuest.ai</h2>
          <p>
            Create your character and embark on a custom adventure with your
            very own AI Dungeon Guide!
          </p>
        </div>

        {/* Dice Simulator */}
        <div className="option-card" onClick={() => navigate("/dice-roller")}>
          <h2>Dice Simulator</h2>
          <p>Roll dice for your campaigns with ease.</p>
        </div>
     

      <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Multiplayer</h2>
          <p> Future Enhancement Coming Soon...</p>
        </div>

      <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Forums</h2>
          <p> Future Enhancement Coming Soon...</p>
        </div>
         </div>
      {/* <div
          className="option-card"
          onClick={() => navigate("/loot-encounter-generator")}
        >
          <h2>Loot & Encounter Generator</h2>
          <p>Generate balanced encounters and treasure for your campaigns.</p>
        </div>
      
      
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Character Sheets</h2>
          <p> Work in progress</p>
        </div>
      
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Inventory Manager</h2>
          <p> Work in progress</p>
        </div>
        
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Loot & Encounter Generator</h2>
          <p> Work in progress</p>
        </div>
    
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Spell Tracker</h2>
          <p> Work in progress</p>
        </div>
       
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>World Building</h2>
          <p> Work in progress</p>
        </div>
     
        
     
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Marketplace</h2>
          <p> Work in progress</p>
        </div>
  
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Soundboard</h2>
          <p> Work in progress</p>
        </div>
        
        <div className="option-card" onClick={() => navigate("/options")}>
          <h2>Session Notes</h2>
          <p> Work in progress</p>
        </div> */}
      <footer className="footer-text">
        <p>More features coming soon to power your adventures!</p>
      </footer>
    </div>
  );
}

export default Options;
