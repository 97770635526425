import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storage, db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./TestPage.css";
import {
  classSkillOptions,
  skillsPerClass,
  races,
  alignments,
  genders,
  classesMale,
  classesFemale,
  backgrounds,
  racialModifiers,
  hitDieByClass,
  startingGoldByClass,
  defaultStats,
  armorData,
  weaponData,
  equipmentPacks,
  maleFirstNames,
  femaleFirstNames,
  lastNames,
  dungeonMasters,
  portraitOptions,
  tabs,
} from "../utils/constants";

//import AdSenseAd from "../components/AdSenseAd";

function rollDice(diceNotation) {
  const [num, sides, multiplier] = diceNotation.split(/d|\s*\*\s*/).map(Number);
  let total = 0;
  for (let i = 0; i < num; i++) {
    total += Math.floor(Math.random() * sides) + 1;
  }
  return multiplier ? total * multiplier : total;
}

function BuySection({ title, data, character, setCharacter }) {
  const [selectedItem, setSelectedItem] = useState("");

  const handleBuy = () => {
    const item = data.find((item) => item.name === selectedItem);
    if (item && character.gold >= item.cost) {
      setCharacter((prev) => ({
        ...prev,
        gold: prev.gold - item.cost,
        inventory: [...prev.inventory, { name: item.name, cost: item.cost }],
      }));
      setSelectedItem("");
    }
  };

  return (
    <div className="buy-section">
      <h3>{title}</h3>
      <select
        onChange={(e) => setSelectedItem(e.target.value)}
        value={selectedItem}
      >
        <option value="">Select an item</option>
        {data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name} - {item.cost}gp
          </option>
        ))}
      </select>
      <button
        className="click-button"
        onClick={handleBuy}
        disabled={!selectedItem}
      >
        Buy
      </button>
    </div>
  );
}

function TestPage() {
  const [activeTab, setActiveTab] = useState("Character Sheet");
  const [errors, setErrors] = useState({});
  const [hasRolledStats, setHasRolledStats] = useState(false);
  const [hasRolledWealth, setHasRolledWealth] = useState(false);
  const [portrait, setPortrait] = useState(null);
  const navigate = useNavigate();
  const [character, setCharacter] = useState(getDefaultCharacter());
  const [statOptions, setStatOptions] = useState([]);
  const [showStatModal, setShowStatModal] = useState(false);
  const [tempPortrait, setTempPortrait] = useState(null); // Temporary portrait for preview
  const [showPortraitModal, setShowPortraitModal] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDungeonMaster, setSelectedDungeonMaster] =
    useState("The Balanced Guide");
  const [dungeonMasterDescription, setDungeonMasterDescription] = useState(
    dungeonMasters["The Balanced Guide"].description
  );

  function getDefaultCharacter() {
    const defaultBackground = backgrounds["Acolyte"];
    return {
      name: "Enter Character Name",
      level: 1,
      experience: 0,
      race: races[0],
      alignment: alignments[0],
      gender: genders[0],
      class: classesMale[0],
      background: "Acolyte",
      stats: defaultStats,
      inventory: defaultBackground.equipment.map((item) => ({
        name: item,
        cost: 0,
      })),
      hitDie: hitDieByClass[classesMale[0]],
      hitPoints: 10,
      gold: defaultBackground.gold,
      racialTraits: racialModifiers[races[0]].description || "",
      proficientSkills: [], // Initialize with an empty array
      dungeonMaster: {
        name: "The Balanced Guide",
        description: dungeonMasters.description,
        image: `/img/DungeonMasters/BalancedGuide.png`,
      },
    };
  }

  const applyRacialModifiers = (stats, race) => {
    const modifiers = racialModifiers[race] || {};
    const modifiedStats = { ...stats };
    for (const [stat, bonus] of Object.entries(modifiers)) {
      if (bonus) {
        modifiedStats[stat] += bonus;
      }
    }
    return modifiedStats;
  };

  useEffect(() => {
    setCharacter((prev) => ({
      ...prev,
      racialTraits: racialModifiers[prev.race]?.description || "",
    }));
  }, [character.race]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCharacter((prev) => {
      if (name === "class" && hasRolledStats) {
        return {
          ...prev,
          class: value,
          hitDie: hitDieByClass[value],
          stats: defaultStats,
          gold: backgrounds[prev.background]?.gold || 0, // Reset gold to default background amount
          proficientSkills: [],
        };
      } else if (name === "class") {
        return {
          ...prev,
          class: value,
          hitDie: hitDieByClass[value],
          gold: backgrounds[prev.background]?.gold || 0, // Reset gold to default background amount
          proficientSkills: [],
        };
      } else if (name === "background") {
        const backgroundInfo = backgrounds[value];
        return {
          ...prev,
          background: value,
          gold: backgroundInfo.gold,
          inventory: backgroundInfo.equipment.map((item) => ({
            name: item,
            cost: 0,
          })),
        };
      }
      return { ...prev, [name]: value };
    });

    // Reset wealth roll allowance if the class is changed
    if (name === "class") {
      setHasRolledWealth(false);
      setHasRolledStats(false);
    } else if (name === "race") {
      setHasRolledStats(false);
    }
  };

  const rollStatsSet = () => {
    const roll = () => {
      const rolls = Array.from(
        { length: 4 },
        () => Math.floor(Math.random() * 6) + 1
      );
      return rolls
        .sort()
        .slice(1)
        .reduce((a, b) => a + b, 0);
    };
    return {
      str: roll(),
      dex: roll(),
      con: roll(),
      int: roll(),
      wis: roll(),
      cha: roll(),
    };
  };

  const rollStats = () => {
    const options = [rollStatsSet(), rollStatsSet(), rollStatsSet()];
    setStatOptions(options);
    setShowStatModal(true); // Show modal with the three options
  };

  const selectStats = (chosenStats) => {
    const modifiedStats = applyRacialModifiers(chosenStats, character.race);
    setCharacter((prev) => ({
      ...prev,
      stats: modifiedStats,
      hitPoints: modifiedStats.con + prev.hitDie,
    }));
    setHasRolledStats(true);
    setShowStatModal(false); // Close the modal
  };

  const rollWealth = () => {
    const classGoldRoll = startingGoldByClass[character.class];
    if (!classGoldRoll) {
      alert("Gold roll configuration not found for this class.");
      return;
    }
    const rolledGold = rollDice(classGoldRoll);
    setCharacter((prev) => ({
      ...prev,
      gold: prev.gold + rolledGold,
    }));
    setHasRolledWealth(true);
  };

  const resetCharacter = () => {
    setCharacter(getDefaultCharacter());
    setHasRolledStats(false);
    setHasRolledWealth(false);
  };

  const generateCharacter = () => {
    const randomGender = genders[Math.floor(Math.random() * genders.length)];
    const firstName =
      randomGender === "Male"
        ? maleFirstNames[Math.floor(Math.random() * maleFirstNames.length)]
        : femaleFirstNames[Math.floor(Math.random() * femaleFirstNames.length)];
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
    const randomName = `${firstName} ${lastName}`;

    const randomRace = races[Math.floor(Math.random() * races.length)];
    const randomAlignment =
      alignments[Math.floor(Math.random() * alignments.length)];
    const randomClass = (randomGender === "Male" ? classesMale : classesFemale)[
      Math.floor(Math.random() * classesMale.length)
    ];
    const randomBackground =
      Object.keys(backgrounds)[
        Math.floor(Math.random() * Object.keys(backgrounds).length)
      ];
    const backgroundInfo = backgrounds[randomBackground];

    setCharacter({
      ...character,
      name: randomName,
      gender: randomGender,
      race: randomRace,
      alignment: randomAlignment,
      class: randomClass,
      background: randomBackground,
      gold: backgroundInfo.gold,
      inventory: backgroundInfo.equipment.map((item) => ({
        name: item,
        cost: 0,
      })),
    });
  };

  const validateCharacter = () => {
    const newErrors = {};
  
    // Name validation
    if (!character.name || character.name === "Enter Character Name") {
      newErrors.name = "Character name is required.";
    }
  
    // Race validation
    if (!character.race) {
      newErrors.race = "Race must be selected.";
    }
  
    // Class validation
    if (!character.class) {
      newErrors.class = "Class must be selected.";
    }
  
    // Background validation
    if (!character.background) {
      newErrors.background = "Background must be selected.";
    }
  
    // Dungeon Guide validation
    if (
      !character.dungeonMaster ||
      !character.dungeonMaster.name ||
      !character.dungeonMaster.description
    ) {
      newErrors.dungeonMaster = "Dungeon Guide must be selected and complete.";
    }
  
    // Portrait validation
    if (!character.portrait) {
      newErrors.portrait = "Character portrait must be selected.";
    }
  
    // Stats validation
    const allDefaultStats = Object.values(character.stats).every(
      (stat) => stat === 8
    );
    if (allDefaultStats) {
      newErrors.stats = "Stats must be rolled and selected.";
    }
  
    // Proficient Skills validation
    if (
      !character.proficientSkills ||
      character.proficientSkills.length === 0
    ) {
      newErrors.proficientSkills = "Proficient skills must be selected.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  

  const createCharacter = async () => {
    if (!validateCharacter()) {
      return; // Stop character creation if validation fails
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        alert("User is not authenticated!");
        return;
      }


       // Ensure dungeonMaster object is valid
    const dungeonMaster = character.dungeonMaster || {};
    const dungeonMasterData = {
      name: dungeonMaster.name || "Unknown Guide",
      description: dungeonMaster.description || "No description provided.",
      image: dungeonMaster.image || "/img/default-portrait.png", // Fallback image
    };

      const characterData = {
        name: character.name,
        level: character.level,
        race: character.race,
        class: character.class,
        alignment: character.alignment,
        background: character.background,
        gender: character.gender,
        stats: character.stats,
        hitPoints: character.hitPoints,
        hitDie: character.hitDie,
        gold: character.gold,
        experience: character.experience,
        inventory: character.inventory,
        proficientSkills: character.proficientSkills,
        dungeonMaster: dungeonMasterData,
        userId: user.uid,
        portrait: character.portrait,
      };

      await addDoc(collection(db, "characters"), characterData);

      alert("Character created successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating character:", error);
      alert("There was an issue creating the character. Please try again.");
    }
  };

  const openSkillModal = () => {
    setShowSkillModal(true);
    setSelectedSkills([]); // Reset selections each time
  };

  const toggleSkill = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((s) => s !== skill));
    } else if (selectedSkills.length < skillsPerClass[character.class]) {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };
  const confirmSkills = () => {
    setCharacter((prev) => ({
      ...prev,
      proficientSkills: [
        ...new Set([
          ...(backgrounds[prev.background]?.skills || []),
          ...selectedSkills,
        ]),
      ],
    }));
    setShowSkillModal(false);
  };

  useEffect(() => {
    setDungeonMasterDescription(
      dungeonMasters[selectedDungeonMaster].description
    );
  }, [selectedDungeonMaster]);

  const handleDungeonMasterChange = (e) => {
    const selected = e.target.value;
    setSelectedDungeonMaster(selected); // Update temporary state
    setDungeonMasterDescription(dungeonMasters[selected]?.description || "");
  };

  const confirmDungeonMaster = () => {
    setCharacter((prev) => ({
      ...prev,
      dungeonMaster: {
        name: selectedDungeonMaster,
        description: dungeonMasterDescription,
        image: `/img/DungeonMasters/${dungeonMasters[selectedDungeonMaster]?.image}`, // Set the image path
      },
    }));
  };

  const confirmPortrait = () => {
    setCharacter((prev) => ({
      ...prev,
      portrait: tempPortrait,
    }));
    setPortrait(tempPortrait);
    setShowPortraitModal(false);
  };

  return (
    <div className="test-page">
      <nav className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={tab === activeTab ? "active" : ""}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </nav>

      <div className="main-content">
        <div className="left-panel">
          {activeTab === "Character Sheet" && (
            <div className="character-options">
              <h2>Character Sheet:</h2>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={character.name}
                onChange={handleInputChange}
              />
              {errors.name && <p className="error-text">{errors.name}</p>}{" "}
              {/* Error message */}
              <label>Race:</label>
              <select
                name="race"
                value={character.race}
                onChange={handleInputChange}
              >
                {races.map((race) => (
                  <option key={race} value={race}>
                    {race}
                  </option>
                ))}
              </select>
              {errors.race && <p className="error-text">{errors.race}</p>}
              <label>Class:</label>
              <select
                name="class"
                value={character.class}
                onChange={handleInputChange}
              >
                {(character.gender === "Male"
                  ? classesMale
                  : classesFemale
                ).map((cls) => (
                  <option key={cls} value={cls}>
                    {cls}
                  </option>
                ))}
              </select>
              <button
                className="click-button"
                onClick={rollStats}
                disabled={hasRolledStats}
              >
                Roll Stats
              </button>
              <label>Background:</label>
              <select
                name="background"
                value={character.background}
                onChange={handleInputChange}
              >
                {Object.keys(backgrounds).map((bg) => (
                  <option key={bg} value={bg}>
                    {bg}
                  </option>
                ))}
              </select>
              <button
                className="click-button"
                onClick={rollWealth}
                disabled={hasRolledWealth}
              >
                Roll Wealth
              </button>
              <button className="click-button" onClick={openSkillModal}>
                Select Skills
              </button>
              {errors.proficientSkills && (
                <p className="error-text">{errors.proficientSkills}</p>
              )}
              <label>Alignment:</label>
              <select
                name="alignment"
                value={character.alignment}
                onChange={handleInputChange}
              >
                {alignments.map((alignment) => (
                  <option key={alignment} value={alignment}>
                    {alignment}
                  </option>
                ))}
              </select>
              <label>Gender:</label>
              <select
                name="gender"
                value={character.gender}
                onChange={handleInputChange}
              >
                {genders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
              <button className="click-button" onClick={generateCharacter}>
                Randomize Character
              </button>
              <button
                className="click-button"
                onClick={() => setShowPortraitModal(true)}
              >
                Select Character Portrait
              </button>
              {errors.portrait && (
                <p className="error-text">{errors.portrait}</p>
              )}
              <button className="click-button" onClick={resetCharacter}>
                Reset Character
              </button>
              <button className="click-button" onClick={createCharacter}>
                Create Character
              </button>
              <button
                className="click-button"
                onClick={() => navigate("/dashboard")}
              >
                Back to Dashboard
              </button>
            </div>
          )}

          {activeTab === "Items / Misc" && (
            <>
              <h2>Items / Misc</h2>
              <BuySection
                title="Armor"
                data={Object.values(armorData).flat()}
                character={character}
                setCharacter={setCharacter}
              />
              <BuySection
                title="Weapons"
                data={Object.values(weaponData).flat()}
                character={character}
                setCharacter={setCharacter}
              />
              <BuySection
                title="Equipment Packs"
                data={equipmentPacks}
                character={character}
                setCharacter={setCharacter}
              />
            </>
          )}
          {/* Dungeon Master Tab */}
          {activeTab === "Dungeon Guide" && (
            <div>
              <h2>Dungeon Guide Persona</h2>
              {selectedDungeonMaster &&
                dungeonMasters[selectedDungeonMaster]?.image && (
                  <img
                    src={`/img/DungeonMasters/${dungeonMasters[selectedDungeonMaster].image}`}
                    alt={`${selectedDungeonMaster} Portrait`}
                    className="dungeon-master-portrait"
                  />
                )}
              <p>{dungeonMasterDescription}</p>

              <label>Select Dungeon Guide:</label>
              <select
                value={selectedDungeonMaster}
                onChange={handleDungeonMasterChange}
              >
                <option value="">Select a Dungeon Guide</option>
                {Object.keys(dungeonMasters).map((dm) => (
                  <option key={dm} value={dm}>
                    {dm}
                  </option>
                ))}
              </select>
              {errors.dungeonMaster && (
                <p className="error-text">{errors.dungeonMaster}</p>
              )}

              <button className="click-button" onClick={confirmDungeonMaster}>
                Set Dungeon Guide
              </button>
            </div>
          )}
        </div>

        <div className="right-panel">
          <div className="character-name">
            <h2>{character.name || "Unnamed Character"}</h2>
            <p>
              Level {character.level} {character.race} {character.class}
            </p>
          </div>
          <div className="character-portrait">
            {portrait ? (
              <img
                src={character.portrait}
                alt="Character Portrait"
                className="portrait"
              />
            ) : (
              <p>No Portrait Selected</p>
            )}
          </div>
          <div className="character-stats">
            <h4>Gold: {character.gold} gp</h4>
            <h4>Hit Die: d{character.hitDie}</h4>
            <h4>HitPoints: {character.hitPoints}</h4>
            <h4>Racial Traits: {character.racialTraits}</h4>
            <div className="character-stats">
              <h3>Stats</h3>
              <div className="stats-grid">
                {Object.entries(character.stats)
                  .filter(([stat]) =>
                    ["str", "dex", "con", "int", "wis", "cha"].includes(stat)
                  )
                  .map(([stat, value]) => (
                    <p key={stat}>
                      {stat.toUpperCase()}: {value}
                    </p>
                  ))}
              </div>
              {errors.stats && <p className="error-text">{errors.stats}</p>}
            </div>
          </div>
          {showPortraitModal && (
            <div className="portrait-modal">
              <div className="modal-content">
                <h2>
                  Select Portrait for {character.race} {character.gender}
                </h2>
                <div className="portrait-grid">
                  {portraitOptions[character.gender]?.[character.race]?.map(
                    (portrait, index) => (
                      <img
                        key={index}
                        src={portrait} // Directly use the correct path
                        alt={`${character.race} ${character.gender} portrait`}
                        className="portrait-option"
                        onClick={() => setTempPortrait(portrait)}
                        style={{
                          border:
                            tempPortrait === portrait
                              ? "2px solid blue"
                              : "none",
                          cursor: "pointer",
                        }}
                      />
                    )
                  )}
                </div>
                <div className="modal-actions">
                  <button className="click-button" onClick={confirmPortrait}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Stat Modal */}
          {showStatModal && (
            <div className="stat-modal">
              <div className="modal-content">
                <h2>Select Your Stats</h2>
                {statOptions.map((option, index) => (
                  <div
                    key={index}
                    className="stat-option"
                    onClick={() => selectStats(option)}
                  >
                    <p className="stat-option">
                      STR: {option.str}, DEX: {option.dex}, CON: {option.con},
                      INT: {option.int}, WIS: {option.wis}, CHA: {option.cha}
                    </p>
                  </div>
                ))}
                <button
                  className="stat-option"
                  onClick={() => setShowStatModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          {showSkillModal && (
            <div className="skill-modal">
              <div className="modal-content">
                <h2>Select Skills for {character.class}</h2>
                <p>Select up to {skillsPerClass[character.class]} skills:</p>
                <div className="skill-list">
                  {classSkillOptions[character.class].map((skill) => (
                    <button
                      key={skill}
                      onClick={() => toggleSkill(skill)}
                      className={
                        selectedSkills.includes(skill) ? "selected" : ""
                      }
                    >
                      {skill}
                    </button>
                  ))}
                </div>
                {/* Update this Confirm button to call confirmSkills */}
                <button className="click-button" onClick={confirmSkills}>
                  Confirm
                </button>
              </div>
            </div>
          )}
          {/* Background Information */}
          <h3>Background</h3>
          <p>
            <strong>{character.background}</strong>
          </p>
          <p>{backgrounds[character.background]?.description}</p>
          <p>
            <strong>Proficient Skills:</strong>{" "}
            {character.proficientSkills && character.proficientSkills.length > 0
              ? character.proficientSkills.join(", ")
              : "None selected"}
          </p>
          <p>
            {" "}
            <strong>Background Proficiencies:</strong>{" "}
            {backgrounds[character.background]?.skills.join(", ")}
          </p>
          {/* Inventory */}
          <h3>Inventory</h3>
          <div className="inventory-grid">
            {character.inventory.length > 0 ? (
              character.inventory.map((item, index) => (
                <p key={index}>{item.name}</p>
              ))
            ) : (
              <p>No items</p>
            )}
          </div>
          <h3>Dungeon Guide</h3>{" "}
          {errors.dungeonMaster && (
            <p className="error-text">{errors.dungeonMaster}</p>
          )}
          {character.dungeonMaster?.image && (
            <img
              src={character.dungeonMaster.image}
              alt={`${character.dungeonMaster.name} Portrait`}
              className="dungeon-master-portrait"
            />
          )}
          <p>{character.dungeonMaster?.name || "No Dungeon Guide selected."}</p>
          <p>{character.dungeonMaster?.description || ""}</p>
          {errors.dungeonMaster && (
            <p className="error-text">{errors.dungeonMaster}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestPage;
