import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { saveCharacterProgress } from "../services/characterService";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import jsSHA from "jssha"; // To generate hash (if needed)
import "./PlayGame.css";
import DicePG from "./DicePG";
import { soundTracks, availableVoices } from "../utils/constants";

function PlayGame() {
  const { state } = useLocation();
  const { character } = state || {};
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();
  const chatBoxRef = useRef(null);
  const [currentTrack, setCurrentTrack] = useState(soundTracks[0].url);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("echo"); // Default to 'echo'

  const handleVoiceChange = (e) => {
    setSelectedVoice(e.target.value);
  };

  useEffect(() => {
    if (!character) navigate("/dashboard");
  }, [character, navigate]);

  useEffect(() => {
    const loadChatHistory = async () => {
      try {
        const characterRef = doc(db, "characters", character.id);
        const characterDoc = await getDoc(characterRef);
        if (characterDoc.exists()) {
          const characterData = characterDoc.data();
          setChatHistory(characterData.chatHistory || []);
        }
      } catch (error) {
        console.error("Error loading chat history:", error);
      }
    };

    loadChatHistory();
  }, [character.id]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const togglePlayback = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset track
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const [ttsVolume, setTtsVolume] = useState(1); // Default to 100%

  const handleTtsVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setTtsVolume(newVolume);
    // Pass the volume value to TTS playback if applicable
  };

  const handleTrackChange = (e) => {
    const selectedTrack = e.target.value;

    // Stop current track before switching
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }

    setCurrentTrack(selectedTrack);
    audioRef.current.load(); // Load the new track
  };

  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const [volume, setVolume] = useState(1); // Default volume is 100%

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const summarizeText = async (text, maxLength = 250) => {
    try {
      const response = await fetch("/api/summarize", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text,
          maxLength,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to summarize text.");
      }

      const data = await response.json();
      return data.summary;
    } catch (error) {
      console.error("Error summarizing text:", error);
      return text; // Fallback to original text if summarization fails
    }
  };

  const generateHash = (content) => {
    const shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.update(content);
    return shaObj.getHash("HEX");
  };

  const handleSpeak = async () => {
    try {
      const lastDMMessage = [...chatHistory]
        .reverse()
        .find((msg) => msg.role === "assistant")?.content;

      if (!lastDMMessage) {
        alert("No Dungeon Guide messages to read.");
        return;
      }

      const characterRef = doc(db, "characters", character.id);
      const characterDoc = await getDoc(characterRef);

      const currentMessageHash = generateHash(lastDMMessage); // Generate a hash of the current message

      if (characterDoc.exists()) {
        const characterData = characterDoc.data();
        const cachedAudioURL = characterData.lastAudioClip;
        const cachedMessageHash = characterData.lastMessageHash;

        // Check if the last message matches the cached one
        if (cachedAudioURL && cachedMessageHash === currentMessageHash) {
          console.log("Playing cached audio from Firebase:", cachedAudioURL);
          const audio = new Audio(cachedAudioURL);
          if (isFinite(ttsVolume)) {
            audio.volume = ttsVolume; // Apply volume only if valid
          }
          audio.play();
          return;
        }
      }

      // Try to find the **Story** section
      let storyMatch = lastDMMessage.match(
        /\*\*Story[\s.:]*\*\*(.+?)(\*\*Action[\s.:]*\*\*|\*\*Choices[\s.:]*\*\*)/is
      );

      let textToSummarize;
      if (storyMatch) {
        textToSummarize = storyMatch[1].trim();
        console.log("Extracted Story Section:", textToSummarize);
      } else {
        console.warn("No 'Story' section found. Using the entire message.");
        textToSummarize = lastDMMessage.trim();
      }

      // Summarize the text
      const summarizedText = await summarizeText(textToSummarize, 250);
      console.log("Summarized Text:", summarizedText);

      // Send to TTS
      const response = await fetch("/api/tts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: summarizedText, voice: selectedVoice }),
      });

      if (!response.ok) {
        throw new Error("Failed to process TTS request.");
      }

      const data = await response.json();
      const audioBase64 = data.audioContent;

      // Save the audio clip to Firebase Storage
      const storage = getStorage();
      const audioRef = ref(
        storage,
        `audio-clips/${character.id}-last-audio.mp3`
      );
      await uploadString(audioRef, audioBase64, "base64");

      const audioURL = await getDownloadURL(audioRef);
      console.log("Audio uploaded to Firebase:", audioURL);

      // Update Firestore with the new audio URL and message hash
      await updateDoc(characterRef, {
        lastAudioClip: audioURL,
        lastMessageHash: currentMessageHash,
      });

      // Play the audio
      const audio = new Audio(audioURL);
      if (isFinite(ttsVolume)) {
        audio.volume = ttsVolume; // Apply volume only if valid
      }
      audio.play();
    } catch (error) {
      console.error("Error with TTS:", error);
      alert("There was an error processing the speech. Please try again.");
    }
  };

  const startGame = async () => {
    if (chatHistory.length > 0) return;

    const assistantRulesMessage = {
      role: "assistant",
      content: `
        You are the most senior and experienced Dungeon Master (DM) for a fantasy role-playing game set in the world of Dungeons and Dragons.You WILL REPEAT THE PLAYERS INFORMATION BACK AT THE START OF THE GAME
        You will assume a persona selected by the player.
        Name: ${character.dungeonMaster.name}
        Persona Description You are to take on and act as if you are them: ${
          character.dungeonMaster.description
        }

        Core Rules and Format
        Character Data: You Must Retain and reference the player's character information throughout the game. You WILL REPEAT THE PLAYERS INFORMATION BACK AT THE START OF THE GAME: 
        This includes:
          Name: ${character.name},
          Gender: ${character.gender}, 
          Race: ${character.race}, 
          Class: ${character.class}, 
          Level: ${character.level}, 
          Alignment: ${character.alignment}, 
          Background: ${character.background},
          Stats: ${JSON.stringify(character.stats)}, 
          Health: ${character.hitPoints || 0},
          HitDie ${character.hitDie || 0} ,
          EXP: ${character.exp || 0}, 
          Gold: ${character.gold || 0}, 
          Proficient Skills: ${character.proficientSkills || "None"}
          Inventory: ${
            character.inventory && character.inventory.length > 0
              ? character.inventory.map((item) => item.name).join(", ")
              : "Nothing"
          }, You are ${character.dungeonMaster.name}, 

        Response Format: Keep your responses concise (maximum 600 characters). 
        Use the following sections:
        Story: The main narrative to set the scene. 
        Action: Describe significant events, battles, or puzzles. 
        Item Gained: + "item(s)" (if applicable).
        Item Taken: + "item(s) removed" (if applicable).
        Puzzle Solved or Monster Defeated (if applicable).
        Choices: Provide 3 numbered options for the player to choose from. Allow players to propose creative alternatives.
        
        Gameplay Mechanics:
        Present a rich narrative with puzzles and challenging battles. Use player stats and dice rolls for skill checks, attacks, and other outcomes.
        Clearly communicate the results of dice rolls (e.g., success or failure) and how they impact the story or encounter.
        In battles or encounters, allow the player to decide each action. Do not auto-resolve entire fights.
        
        Starting the Game:
        When the player says "Start Game," repeat their character information back to them and provide an opening scene.
        Maintain an immersive tone and adapt the persona to fit the character of the selected Dungeon Master.
        
        Interactive Creativity:
        Encourage player creativity. If the player proposes actions outside of provided choices, adapt the story and outcomes dynamically.

        Track Player Progress:
        Continuously update and reference the player’s stats, items, gold, and experience points.
        Reflect any changes in the narrative and ensure progression feels meaningful.
        
        Starting Instructions:
        When prompted with "Start Game," introduce the world, set the initial scene, and provide the first set of choices. Reference the Dungeon Master persona to shape the tone and style of storytelling.


      `,
    };

    const characterDataMessage = {
      role: "user",
      content: `Start Game. The player's character data is as follows:
          Name: ${character.name},
          Gender: ${character.gender}, 
          Race: ${character.race}, 
          Class: ${character.class}, 
          Level: ${character.level}, 
          Alignment: ${character.alignment}, 
          Background: ${character.background},
          Stats: ${JSON.stringify(character.stats)}, 
          Health: ${character.hitPoints || 0},
          HitDie ${character.hitDie || 0} ,
          EXP: ${character.exp || 0}, 
          Gold: ${character.gold || 0}, 
          Proficient Skills: ${character.proficientSkills || "None"}
          Inventory: ${
            character.inventory && character.inventory.length > 0
              ? character.inventory.map((item) => item.name).join(", ")
              : "Nothing"
          }, You are ${
        character.dungeonMaster.name
      }, Your persona to act like is: ${character.dungeonMaster.description}.
          You WILL REPEAT THE PLAYERS INFORMATION BACK AT THE START OF THE GAME Start the adventure in a fantasy setting..`,
    };

    const response = await sendMessageToChatGPT([
      assistantRulesMessage,
      characterDataMessage,
    ]);
    const initialHistory = [response];
    setChatHistory(initialHistory);

    saveCharacterProgress(
      character.id,
      0,
      null,
      null,
      response.content,
      initialHistory
    );
  };

  const sendMessageToChatGPT = async (messages) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini-2024-07-18",
          messages,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );
      return response.data.choices[0].message;
    } catch (error) {
      console.error("Error communicating with ChatGPT:", error);
      return {
        role: "assistant",
        content: "Something went wrong. Please try again later.",
      };
    }
  };

  const handleSendMessage = async () => {
    const playerMessage = { role: "user", content: userInput };
    const newHistory = [...chatHistory, playerMessage];

    const response = await sendMessageToChatGPT(newHistory);
    const updatedHistory = [...newHistory, response];
    setChatHistory(updatedHistory);
    setUserInput("");

    saveCharacterProgress(
      character.id,
      0,
      null,
      null,
      response.content,
      updatedHistory
    );
  };
  const [showDiceModal, setShowDiceModal] = useState(false);

  const toggleDiceModal = () => {
    setShowDiceModal(!showDiceModal);
  };

  return (
    <div className="play-game-container">
      <h1>
        {character.dungeonMaster.name} Adventure with {character.name}
      </h1>
      {/* Chat Box */}
      <div className="chat-box" ref={chatBoxRef}>
        {chatHistory.map((msg, index) => (
          <div
            key={index}
            className={msg.role === "user" ? "user-message" : "dm-message"}
          >
            <ReactMarkdown>{msg.content}</ReactMarkdown>
            {/* Add a hard line break after each user message */}
            {msg.role === "user" && <hr className="message-divider" />}
          </div>
        ))}
      </div>

      {chatHistory.length === 0 && (
        <button className="back-button" onClick={startGame}>
          Start Game
        </button>
      )}

      <div className="chat-input-container">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your response here..."
          className="chat-input"
        />
        <button className="send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>

      <div className="portraits-and-actions">
        <div className="portrait-wrapper">
          <img
            src={character.dungeonMaster.image}
            alt={`${character.dungeonMaster.name} portrait`}
            className="dungeon-master-portrait-pg"
          />
        </div>

        <div className="pg-buttons">
          <button className="pg-button listen-button " onClick={handleSpeak}>
            <img src="/buttons/Listen.png" alt="Listen" />
          </button>

          {/* Roll Dice Button */}
          <button className="pg-button dice-button" onClick={toggleDiceModal}>
            <img src="/buttons/Rolldice.png" alt="Roll Dice" />
          </button>

          {/* Settings Button */}
          <button
            className="pg-button settings-button"
            onClick={toggleSettingsModal}
          >
            <img src="/buttons/Settings.png" alt="Settings" />
          </button>

          {/* Dice Modal */}
          {showDiceModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <DicePG />
                <button className="close-modal" onClick={toggleDiceModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="portrait-wrapper">
          <img
            src={character.portrait}
            alt={`${character.name} portrait`}
            className="player-character-portrait-pg"
          />
        </div>
      </div>
      {/* Settings Modal */}
      {showSettingsModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Settings</h2>

            {/* Music Controls */}
            <div className="music-controls">
              <select value={currentTrack} onChange={handleTrackChange}>
                {soundTracks.map((track) => (
                  <option key={track.url} value={track.url}>
                    {track.name}
                  </option>
                ))}
              </select>
              <button
                className="pg-button play-button"
                onClick={togglePlayback}
              >
                <img
                  src={isPlaying ? "/buttons/Stop.png" : "/buttons/Play.png"}
                  alt={isPlaying ? "Stop" : "Play"}
                  className="icon"
                />
              </button>
            </div>
            <div className="volume-control">
              <label htmlFor="music-volume-slider">Music Volume:</label>
              <input
                id="music-volume-slider"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={volume}
                onChange={handleVolumeChange}
              />
            </div>

            <div className="voice-selector">
              <label htmlFor="voice-selector">Voice:</label>
              <select
                id="voice-selector"
                value={selectedVoice}
                onChange={handleVoiceChange}
              >
                {availableVoices.map((voice) => (
                  <option key={voice.id} value={voice.id}>
                    {voice.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="volume-control">
              <label htmlFor="tts-volume-slider">Dungeon Guide Volume:</label>
              <input
                id="tts-volume-slider"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={ttsVolume}
                onChange={handleTtsVolumeChange}
              />
            </div>

            {/* Back to Dashboard */}
            <button
              className="pg-button back-button"
              onClick={() => navigate("/dashboard")}
            >
              Back to Dashboard
            </button>

            <button
              className="pg-button close-modal"
              onClick={toggleSettingsModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <audio ref={audioRef} loop>
        <source src={currentTrack} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default PlayGame;
