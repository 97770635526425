import React, { useState } from "react";
import "./LootEncounterGenerator.css";
import {
  generateEncounterMonsters,
  generateLoot,
} from "../../utils/lootEncounterGenerator";

const LootEncounterGenerator = () => {
  const [partySize, setPartySize] = useState(4);
  const [difficulty, setDifficulty] = useState("Medium");
  const [lootValue, setLootValue] = useState("Medium");
  const [encounterResult, setEncounterResult] = useState(null);
  const [lootResult, setLootResult] = useState(null);

  const handleGenerateEncounter = () => {
    const monsters = generateEncounterMonsters(partySize, difficulty);
    setEncounterResult({
      monsters: monsters,
      totalXP: monsters.reduce((sum, monster) => sum + monster.xp, 0),
    });
  };

  const handleGenerateLoot = () => {
    const loot = generateLoot(lootValue);
    setLootResult(loot);
  };

  return (
    <div className="loot-encounter-generator">
      <h1>Loot and Encounter Generator</h1>
      <div className="generator-controls">
        <div>
          <label>Party Size</label>
          <input
            type="number"
            min="1"
            max="10"
            value={partySize}
            onChange={(e) => setPartySize(Number(e.target.value))}
          />
        </div>
        <div>
          <label>Encounter Difficulty</label>
          <select
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
            <option value="Deadly">Deadly</option>
          </select>
        </div>
        <div>
          <label>Loot Value</label>
          <select
            value={lootValue}
            onChange={(e) => setLootValue(e.target.value)}
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
        <button onClick={handleGenerateEncounter}>Generate Encounter</button>
        <button onClick={handleGenerateLoot}>Generate Loot</button>
      </div>
      <div className="results">
        <h2>Encounter</h2>
        {encounterResult && (
          <div>
            <p>Total XP: {encounterResult.totalXP}</p>
            <ul>
              {encounterResult.monsters.map((monster, index) => (
                <li key={index}>
                  {monster.name} (CR {monster.cr}, XP {monster.xp})
                </li>
              ))}
            </ul>
          </div>
        )}
        <h2>Loot</h2>
        {lootResult && (
          <ul>
            {lootResult.map((item, index) => (
              <li key={index}>
                {item.name} {item.amount && `- ${item.amount} gp`}{" "}
                {item.rarity && `(${item.rarity})`}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LootEncounterGenerator;
